<template>

  <div>

    <user-list-add-new
      v-if="roles"
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roles"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <!-- <users-list-filters
      v-if="roles"
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roles"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->

    <!-- Table Container Card -->
    <div v-if="loading">
      Loading...
    </div>
    <div v-else-if="error">
      Error: {{ error.message }}
    </div>
    <div v-else-if="cursoVersionData">
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Show</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
                <b-button
                  variant="primary"
                  @click="isAddNewUserSidebarActive = true"
                >
                  <span class="text-nowrap">Add User</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="cursoVersionData.matriculas"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No se encontraron registros"
        >

          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle" />
              <strong> Cargando...</strong>
            </div>
          </template>

          <!-- Column: User -->
          <template #cell(user)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.avatar"
                  :text="avatarText(`${data.item.nombres} ${data.item.paterno}`)"
                  :variant="`light-${resolveUserRoleVariant(data.item.user?(data.item.user.roles.length>0)?data.item.user.roles[0].nombre:'':'')}`"
                  :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
                />
              </template>
              <b-link
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.nombres }} {{ data.item.paterno }} {{ data.item.materno }}
              </b-link>
              <small class="text-muted">@{{ data.item.user?data.item.user.username:'*****' }}</small>
            </b-media>
          </template>

          <!-- Column: Role -->
          <template #cell(roles)="data">
            <template v-if="data.item.user">
              <div
                v-for="(rol, i) in data.item.user.roles"
                :key="i"
                class="text-nowrap"
              >
                <feather-icon
                  :icon="resolveUserRoleIcon(rol.nombre)"
                  size="18"
                  class="mr-50"
                  :class="`text-${resolveUserRoleVariant(rol.nombre)}`"
                />
                <span class="align-text-top text-capitalize">{{ rol.nombre }}</span>
              </div>
            </template>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge
              v-if="data.item.user"
              pill
              :variant="`light-${resolveUserStatusVariant(data.item.user.status)}`"
              class="text-capitalize"
            >
              {{ resolveUserStatus(data.item.user.status) }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span
                v-if="!loading"
                class="text-muted"
              >Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ cursoVersionData.matriculas.length }} usuarios</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-if="!loading"
                v-model="currentPage"
                :total-rows="cursoVersionData.matriculas.length"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BSpinner,
} from 'bootstrap-vue'
import { useToast } from 'vue-toastification/composition'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useQuery, useResult } from '@vue/apollo-composable'
import { ref, onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
// import UsersListFilters from './ParticipantesListFilters.vue'
import useUsersList from './useUsersList'
import userStoreModule from '../../../../../users/userStoreModule'
import UserListAddNew from './ParticipantesListAddNew.vue'
// import GET_PERSONA_USER from '../../../../../../graphql/usuario/PersonaUser.gql'
import FIND_ROLES from '../../../../../../graphql/usuario/roles/Roles.gql'
import SHOW_CURSO_VERSION from '../../../../../../graphql/cursos/curso/version/ShowCursoVersion.gql'

export default {
  components: {
    // UsersListFilters,
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,

    vSelect,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const toast = useToast()
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatus,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useUsersList()

    const {
      loading, result, error, onError, onResult,
    } = useQuery(SHOW_CURSO_VERSION, () => ({ cursoVersionId: router.currentRoute.params.id }))

    const cursoVersionData = useResult(result).value
    /*
    const {
      result,
      loading,
      onError,
      onResult,
    } = useQuery(GET_PERSONA_USER, {
      limit: perPage,
      page: currentPage,
      search: searchQuery,
      role_id: '',
      // categoria: categoriaFilter,
      // carrera: carreraFilter,
      estado: statusFilter,
    })
    const personas = useResult(result, null, data => data.getPersonaPaginate.data)
    const personasPageInfo = useResult(result, null, data => data.getPersonaPaginate.pageInfo)

*/
    const queryRoles = useQuery(FIND_ROLES)
    const roles = useResult(queryRoles.result, null, data => data.roles)

    onError(err => {
      toast({
        component: ToastificationContent,
        props: {
          title: err.message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

    onResult(queryResult => {
      console.log(queryResult.data)
      console.log(queryResult.loading)
      console.log(queryResult.networkStatus)
      console.log(queryResult.stale)
    })

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      cursoVersionData,
      loading,
      error,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatus,
      roles,
      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
